import {useContext} from 'react';
import {Tooltip} from 'react-tooltip';
import {Button} from "primereact/button";
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {FilterMatchMode} from "primereact/api";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {DataLoadingIndicator} from "../common/DataLoadingIndicator";
import {isSmallScreen, isSmallScreenVertical} from "../utilities/CommonUtils";
import {Slider} from "primereact/slider";
import {InputNumber} from "primereact/inputnumber";
import {useSessionStorage} from "primereact/hooks";
import {AppContext} from "../App";

export default function GameList() {
  const title = 'Lista gier';
  const {games} = useContext(AppContext);
  const {showList} = useContext(AppContext);
  const [globalFilterValue, setGlobalFilterValue] = useSessionStorage('', "gamelist.globalFilterValue");
  const [playersCountFilter, setPlayersCountFilter] = useSessionStorage(null, "gamelist.playersCountFilter");
  const [filters, setFilters] = useSessionStorage({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    minPlayers: {value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO},
    maxPlayers: {value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO}
  }, "gamelist.filters");
  const toggleableColumns = [
    {field: "minPlayers", header: "Min. graczy", sortable: true, filter: true, align: "center"},
    {field: "maxPlayers", header: "Max. graczy", sortable: true, filter: true, align: "center"},
    {field: "minMinutes", header: "Min. czas", sortable: true, filter: false, align: "center"},
    {field: "maxMinutes", header: "Max. czas", sortable: true, filter: false, align: "center"},
    {field: "expansions", header: "Dodatki", sortable: false, filter: false, align: "left", italic: true}
  ]
  const defaultVisibleColumns = ["minPlayers", "maxPlayers", "minMinutes", "maxMinutes", "expansions"];
  const smallScreenVisibleColumns = [];
  const [visibleColumns, setVisibleColumns] = useSessionStorage(toggleableColumns.filter(column => {
    const columnSet = isSmallScreen() ? smallScreenVisibleColumns : defaultVisibleColumns;
    return columnSet.indexOf(column.field) !== -1;
  }), "gamelist.visibleColumns");
  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    const orderedSelectedColumns = toggleableColumns.filter(column => selectedColumns.some((selectedColumn) => selectedColumn.field === column.field));
    setVisibleColumns(orderedSelectedColumns);
    event.stopPropagation();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = {...filters};

    _filters['global'].value = value.trim();

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const coverTooltipTemplate = (game) => {
    return <span className="tooltip-cursor pi pi-image"
                 data-tooltip-id="game-cover-tooltip"
                 data-tooltip-html={`<img class="cover" src="data:image/jpeg;base64,${game.cover}" alt="${game.name + " (brak okładki)"}"/>`}></span>
  }

  const expansionsTemplate = (game) => {
    return game.expansions.join(", ");
  }

  const customBodyTemplates = {"expansions": expansionsTemplate};

  const headerTemplate = () => {
    return <>
      <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
        <h3>{title}</h3>
        <div className="d-flex align-items-center gap-3 ">
          <MultiSelect value={visibleColumns} options={toggleableColumns} optionLabel="header" onChange={onColumnToggle}
                       dropdownIcon="pi pi-sliders-v" className="me-2 icon-only" tooltip="Kolumny" tooltipOptions={{position: "bottom"}}/>
          <span>Liczba graczy</span>
          <span>
            <InputNumber inputClassName="text-center" size={4} inputStyle={{height: "2rem"}} min={1} max={12}
                         value={playersCountFilter} onChange={event => onPlayersCountFilterChange(event.value)}/>
            <Slider min={1} max={12} value={playersCountFilter}
                    onChange={event => onPlayersCountFilterChange(event.value)}/>
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search"/>
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Szukaj..." className="w-100"/>
          </span>
          <Button size="small" disabled={!playersCountFilter && !globalFilterValue} icon="pi pi-times" onClick={() => resetAdvancedFilters()}/>
        </div>
      </div>
    </>
  }

  const onPlayersCountFilterChange = value => {
    setPlayersCountFilter(value);
    let _filters = {...filters};
    _filters['minPlayers'].value = value;
    _filters['maxPlayers'].value = value;
    setFilters(_filters);
  };

  const resetAdvancedFilters = () => {
    onPlayersCountFilterChange(null);
    onGlobalFilterChange({target: {value: ''}});
  }

  return <>
    <Panel hidden={!showList} className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content"}}}>
      <Tooltip id="game-cover-tooltip" delayShow={500} place="right" className="tooltip-content"/>
      <DataTable size="small" className="flex-grow-1" value={games} filters={filters} globalFilterFields={['name']}
                 sortField="name" sortOrder={1}
                 emptyMessage="Brak wyników."
                 scrollable scrollHeight="flex"
                 loading={games.length === 0} loadingIcon={<DataLoadingIndicator/>}
                 stripedRows
      >
        <Column field="cover" align="center" body={coverTooltipTemplate} hidden={isSmallScreen()}/>
        <Column field="name" header="Tytuł" sortable/>
        {visibleColumns.map(column =>
          <Column key={column.field} field={column.field} header={column.header} align={column.align}
                  sortable={column.sortable} filter={column.filter} showFilterMenu={false}
                  body={customBodyTemplates[column.field]} bodyClassName={column.italic ? "fst-italic" : ""}/>
        )}
      </DataTable>
    </Panel>
  </>
}
