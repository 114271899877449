import './css/App.css';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import {createContext, useEffect, useState} from "react";
import {Button} from "primereact/button";
import GameGallery from "./page/GameGallery";
import GameList from "./page/GameList";
import {Toolbar} from "primereact/toolbar";
import Dexie from 'dexie';

export const AppContext = createContext(null);

export default function App() {
  const API_LIST_URL = process.env.REACT_APP_API_LIST_URL;
  const API_CHECK_URL = process.env.REACT_APP_API_CHECK_URL;
  const cache = new Dexie("games");
  cache.version(1).stores({
    games: "name",
    updates: "lastUpdate"
  });
  const [games, setGames] = useState([]);
  const [showGallery, setShowGallery] = useState(true);
  const [showList, setShowList] = useState(false);
  const appContextData = {games, showGallery, showList};

  useEffect(() => {
    fetch(API_CHECK_URL).then(response => response.json()).then(lastUpdateData => {
      cache.updates.toCollection().first().then(storedLastUpdate => {
        const oldDate = storedLastUpdate ? new Date(storedLastUpdate.lastUpdate) : undefined;
        const newDate = new Date(lastUpdateData.lastUpdate);
        if (!oldDate || newDate > oldDate) {
          cache.games.clear().then(() => fetch(API_LIST_URL)).then(response => response.json()).then(fetchedGames => cache.games.bulkPut(fetchedGames).then(() => setGames(fetchedGames))).then(() => cache.updates.clear()).then(() => cache.updates.put(lastUpdateData));
        } else {
          cache.games.toArray().then(storedGames => setGames(storedGames));
        }
      });
    });
  }, [API_LIST_URL, API_CHECK_URL]); // eslint-disable-line

  const menu = <Toolbar start={() =>
    <div>
      <Button label="Galeria" icon="pi pi-images" className="m-1" onClick={() => {
        setShowGallery(true);
        setShowList(false);
      }}/>
      <Button label="Lista" icon="pi pi-list" className="m-1" onClick={() => {
        setShowGallery(false);
        setShowList(true);
      }}/>
    </div>}/>;

  return <AppContext.Provider value={appContextData}>
    {menu}
    {<GameGallery/>}
    {<GameList/>}
  </AppContext.Provider>
}
