export const isSmallScreen = () => {
  return isSmallScreenHorizontal() || isSmallScreenVertical();
}

export const isSmallScreenHorizontal = () => {
  return window.innerHeight < 720;
}

export const isSmallScreenVertical = () => {
  return window.innerWidth < 720;
}